import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Container, Heading, Menu, Section } from 'react-bulma-components'
import { StaticImage } from 'gatsby-plugin-image'
import config from '../config/config.json'

const IndexPage = () => {

  const { t } = useTranslation(['page_home'/* , 'slugs' */])

  const tMeta = t('meta')
  const tHero = t('hero')

  const isBrowser = typeof window !== "undefined"

  if (!isBrowser) return <><>{' '}</></>

  return (
    <Layout>
      <Seo
        title={tMeta.title}
        description={tMeta.description}
      />
      <Section>
        <Container>
          <Menu>
            <Menu.List>
              {config.pages.map(page => {
                const label = page?.title ?? page?.slug
                return (
                  <Menu.List.Item renderAs='a' href={`/${page.slug}`}>{label}</Menu.List.Item>
                )
              })}
            </Menu.List>
          </Menu>
        </Container>
      </Section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;